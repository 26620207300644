import React, { useEffect, useState } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft, faSquareMinus } from "@fortawesome/free-solid-svg-icons";
import Modal from "./Model";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
});

export default function Products() {
  const [isLoading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [isCashModalOpen, setIsCashModalOpen] = useState(false);
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const [input, setInput] = useState("");
  const [showCashButton, setShowCashButton] = useState(false);
  const [showCardButton, setShowCardButton] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (selectedCategory === "all") {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) => product.category === selectedCategory);
      setFilteredProducts(filtered);
    }
  }, [selectedCategory]);

  const uniqueCategories = [...new Set(products.map((product) => product.category))];

  useEffect(() => {
    Axios.get("https://tuckshop.johnstonecentralscouts.org.uk/getAllProducts.php").then((response) => {
      const productsData = response.data.map((element) => JSON.parse(element));
      setFilteredProducts(productsData);
      setProducts(productsData);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    products.forEach(product => {
      if (barcode.endsWith(product.ean13) && barcode!="" && product.ean13!="") {
        addProduct(product.name, product.price, 1);
        setBarcode("");
      }
    });
    
  }, [barcode]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const newText = barcode + e.key;
      setBarcode(newText);
    };
    window.addEventListener("keyup", handleKeyDown);
    return () => {
      window.removeEventListener("keyup", handleKeyDown);
    };
  }, [barcode]);

  useEffect(() => {
    let newTotal = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotal(newTotal);
    setShowCashButton(newTotal > 0);
    setShowCardButton(newTotal >= 1);
  }, [cartItems]);

  const addProduct = (name, price, quantity) => {
    const existingProduct = cartItems.find((item) => item.name === name);
    if (existingProduct) {
      const updatedCartItems = cartItems.map((item) => (item.name === name ? { ...item, quantity: item.quantity + quantity } : item));
      setCartItems(updatedCartItems);
    } else {
      const newProduct = { name, price, quantity };
      setCartItems([...cartItems, newProduct]);
    }
  };
  const removeProduct = (name) => {
    const existingProduct = cartItems.find((item) => item.name === name);
    if (existingProduct) {
      if (existingProduct.quantity === 1) {
        const updatedCartItems = cartItems.filter((item) => item.name !== name);
        setCartItems(updatedCartItems);
      } else {
        const updatedCartItems = cartItems.map((item) => (item.name === name ? { ...item, quantity: item.quantity - 1 } : item));
        setCartItems(updatedCartItems);
      }
    }
  };
  const toggleModal = () => {
    setIsCashModalOpen(!isCashModalOpen);
  };

  const toggleCardModal = () => {
    setIsCashModalOpen(!isCardModalOpen);
  };

  const handleButtonClick = (value) => {
    setInput(value === "C" ? "" : input + value);
  };

  const handleComplete = (input, total) => {
    if (input >= total) {
      const url_log = "https://tuckshop.johnstonecentralscouts.org.uk/logsale.php";
      Axios.post(url_log, cartItems)
        .then((response) => {
          console.log("Order submitted successfully", response);
          setIsCashModalOpen(false);
          setIsCardModalOpen(false);
          setCartItems([]);
          setTotal(0);
          setInput(0);
        })
        .catch((error) => {
          console.error("Error submitting order", error);
        });
      //reset
    }
  };

  const handleTabClick = (category) => {
    setSelectedCategory(category);
  };

  if (isLoading) {
    return (
      <div className="loading-page">
        <div className="loading-spinner">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      </div>
    );
  }
  return (
    <>
      <div id="product-list" className="column-left">
        <h2>Tuckshop Till</h2>

        <div className="category-tabs">
          <button className="tab-button" onClick={() => handleTabClick("all")}>
            All
          </button>
          {uniqueCategories.map((category) => (
            <button className="tab-button" key={category} onClick={() => handleTabClick(category)}>
              {category}
            </button>
          ))}
        </div>

        <div className="tab" id="tab-container"></div>
        <div className="content-container" id="content-container">
          <div id="All-container" style={{ display: "flex" }} className="product-container">
            {filteredProducts.map((item, index) => {
              return (
                <div key={index} className="product-card" onClick={() => addProduct(item.name, item.price, 1)}>
                  <img src={"https://tuckshop.johnstonecentralscouts.org.uk/img/" + item.image} width={"150px"} height={"150px"} alt={item.name} />
                  <h4>{item.name}</h4>
                  <p>{formatter.format(item.price)}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div id="cart" className="column-right">
        <h2>Basket</h2>
        <table className="cart-table">
          <thead>
            <tr>
              <th>Name</th>
              <th></th>
              <th>Qty</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td onClick={() => removeProduct(item.name)}>
                  <FontAwesomeIcon icon={faSquareMinus} size="2x" />
                </td>
                <td>{item.quantity}</td>

                <td>{formatter.format(item.price * item.quantity)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <hr />
        <p>
          Total: <span id="total">{formatter.format(total)}</span>
        </p>
        <div className="button-container">
          {showCashButton && (
            <button className="button-cash" id="pay-cash" onClick={toggleModal}>
              Cash
            </button>
          )}
          {showCardButton && (
            <button className="button-card" id="pay-card" onClick={toggleCardModal}>
              Card
            </button>
          )}
        </div>
      </div>

      <Modal isOpen={isCashModalOpen} onClose={toggleModal}>
        <h2>Cash Payment</h2>
        <div className="calculator">
          <div className="screen">
            <div className="result">Total: {formatter.format(total)}</div>
            <div className="input">Paid: {formatter.format(input)}</div>
            <div className="result">Change: {formatter.format(input - total)}</div>
          </div>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td className="calctd">
                  <button className="calcbutton" onClick={() => handleButtonClick("7")}>
                    7
                  </button>
                </td>
                <td className="calctd">
                  <button className="calcbutton" onClick={() => handleButtonClick("8")}>
                    8
                  </button>
                </td>
                <td className="calctd">
                  <button className="calcbutton" onClick={() => handleButtonClick("9")}>
                    9
                  </button>
                </td>
              </tr>
              <tr>
                <td className="calctd">
                  <button className="calcbutton" onClick={() => handleButtonClick("4")}>
                    4
                  </button>
                </td>
                <td className="calctd">
                  <button className="calcbutton" onClick={() => handleButtonClick("5")}>
                    5
                  </button>
                </td>
                <td className="calctd">
                  <button className="calcbutton" onClick={() => handleButtonClick("6")}>
                    6
                  </button>
                </td>
              </tr>
              <tr>
                <td className="calctd">
                  <button className="calcbutton" onClick={() => handleButtonClick("1")}>
                    1
                  </button>
                </td>
                <td className="calctd">
                  <button className="calcbutton" onClick={() => handleButtonClick("2")}>
                    2
                  </button>
                </td>
                <td className="calctd">
                  <button className="calcbutton" onClick={() => handleButtonClick("3")}>
                    3
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <button className="calcbutton" onClick={() => handleButtonClick(".")}>
                    .
                  </button>
                </td>
                <td>
                  <button className="calcbutton" onClick={() => handleButtonClick("0")}>
                    0
                  </button>
                </td>
                <td>
                  <button className="calcbutton" onClick={() => handleButtonClick("C")}>
                    <FontAwesomeIcon icon={faDeleteLeft} />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <button className="calcbutton" onClick={() => handleComplete(input, total)}>
            Submit
          </button>
        </div>
      </Modal>

      <Modal isOpen={isCardModalOpen} onClose={toggleCardModal}>
        <h2>Modal Content</h2>
        <p>This is the content of the modal.</p>
      </Modal>
    </>
  );
}
