import logo from "./logo.svg";
import "./App.css";
import Products from "./Products";

function App() {


  return (
    <div className="App">
      <div className="row">
        <Products/>
      </div>
    </div>
  );
}

export default App;
